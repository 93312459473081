import * as styles from './index.module.scss';

import Container from '../container';
import { Link } from 'gatsby';
import React from "react";

const Pagination = ({ current, total, locale }) => {
  const pages = [];
  const slug = locale === 'de' ? 'archiv' : 'archive';
  const first = locale === 'de' ? 'Erste' : 'First';
  const last = locale === 'de' ? 'Letzte' : 'Last';

  if (current > 1) {
    pages.push((
      <Link key='first' to={`/${locale}/${slug}/1`} className={styles.page}>
        {first}
      </Link>
    ))
  }

  [current - 3, current - 2, current -1, current, current + 1, current + 2, current + 3].map(page => {
    if (page < 1 || page >= total) return null;

    pages.push((
      <Link key={page} data-active={page === current} to={`/${locale}/${slug}/${page}`} className={styles.page}>
        {page}
      </Link>
    ))

    return null
  })

  if (current < total - 1) {
    pages.push((
      <Link key='last' to={`/${locale}/${slug}/${total - 1}`} className={styles.page}>
        {last}
      </Link>
    ))
  }

  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.pagination}>
          {pages}
        </div>
      </Container>
    </div>
  )
};

export default Pagination;
