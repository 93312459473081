import Layout from '../../components/layout/index';
import PageHeader from '../../components/page/header';
import Pagination from '../../components/pagination';
import PostsGrid from '../../components/posts/grid';
import React from "react";
import Seo from '../../components/seo';
import { getField } from '../../helper.js'
import { graphql } from 'gatsby';

const Archive = ({ data, pageContext: { locale='de', pathDe, pathEn, pageNumber, totalPages } }) => {
  const { page } = data

  const title = getField(page, 'title', locale)
  const metaTitle = getField(page, 'metaTitle', locale)
  const metaDescription = getField(page, 'metaDescription', locale)
  const keywords = getField(page, 'keywords', locale)
  const sharingImage = getField(page?.coverImage, 'file', locale)?.src

  const localizedUrls = {
    de: pathDe,
    en: pathEn
  };

  const posts = data?.recipes?.edges.map( node => node.node);

  return (
    <>
      <Seo
        locale={locale}
        localizedUrls={localizedUrls}
        title={title}
        sharingTitle={title}
        sharingDescription={metaDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        keywords={keywords}
        image={sharingImage}
      />
      <Layout locale={locale} localizedUrls={localizedUrls}>
        <PageHeader title={title} locale={locale}/>
        <PostsGrid posts={posts} locale={locale}/>
        <Pagination current={pageNumber} total={totalPages} locale={locale}/>
      </Layout>
    </>
  )
}

export default Archive;

export const query = graphql`
  query ArchivePageQuery($skip: Int!, $postsPerPage: Int!, $archivePageId: String!) {
    recipes: allCmsRecipe(limit: $postsPerPage, skip: $skip, sort: {order: DESC, fields: publishDate}) {
      edges {
        node {
          ...RecipeTile
        }
      }
    }

    page: cmsPage(id: { eq: $archivePageId }) {
      titleDe
      titleEn
      metaTitleDe
      metaTitleEn
      metaDescriptionDe
      metaDescriptionEn
      keywordsDe
      keywordsEn
      coverImage {
        fileDe {
          src
        }
        fileEn {
          src
        }
      }
    }
  }
`;
